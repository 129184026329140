@media (min-width: 992px) {
	.container{
		max-width: 1000px;
	}
}
.container-lg{
	max-width: 1100px;
}
.accordion-button {
	font-size: 14px;
	padding: 3px 15px 3px;
}



@media (max-width: 991px) {

	//TOP NAVBAR

	.navbar {
		padding-bottom: 0;

		&-wrapper {

			&-item {
				padding: 0;
				margin-left: 10px;

				a {
					font-size: 12px;
				}
			}
		}
	}

	//HEADER

	.headertext {
		margin-top: 20px;
		margin-right: 10px;
		text-align: right;
		font-size: 14px;
	}
	.social-wrapper {
		margin-right: 20px;

	}
	.main_logo {
		transform: scale(0.8);
		margin-left: 0;

	}
	//NAVBAR

	.menubar {

		width: 100%;
		justify-content: space-around;
		margin-left: 0;

		&-links {
			font-weight: normal;
			font-size: 14px;
			padding: 6px 6px 6px 6px;
		}
	}

	// SLIDER

	.slider {

		.carousel-item {
			height: 440px;

			&-info {
				top: 20px;
				padding: 10px 15px;

				.title {
					font-weight: normal;
					font-size: 22px;
					margin-bottom: 10px;
					padding-right: 30px;
				}

				.miles {
					font-size: 16px;
					margin-bottom: 10px;
					font-weight: normal;
				}

				.price {
					margin-bottom: 10px;
					font-weight: normal;
				}

				button {
					font-size: 16px;
				}
			}


		}
	}


	//ACCORDION

	.visibility {
		display: none;
	}

	.browse-title {
		height: 40px;
		padding: 5px 25px;
		font-size: 20px;
	}

	.queries {
		//padding: 10px 10px 20px;
	}
	.queries-par {
		font-size: 14px !important;
		padding: 10px 20px !important;

	}

	// FOOTER

	.footer {

		&-title {
			text-transform: uppercase;
			padding: 10px 10px 0 20px;
			color: #b4b4b4;
			margin-bottom: 20px;

		}

		&-info {
			color: #b4b4b4;
			line-height: 25px;
			padding: 0 10px 0 20px;
		}

		.size {
			width: 60%;
			//margin: 0 auto;
		}

	}

	// BELOW FOOTER

	.below-footer {

		justify-content: space-between;
		align-items: center;
		padding: 20px 20px 20px;

		&-pas {
			margin-left: 5px;
			font-size: 12px;
		}

		&-links {
			margin-right: 20px;

			a {
				color: #d3d3d3;
				text-decoration: none;
				margin-left: 20px;
				font-size: 14px;
			}
		}
	}

	.arrivals {
		margin-top: 20px;

		&-title {
			margin-right: 10px;
			font-size: 16px;
		}

		&-grey-block {
			height: 10px;
			padding-bottom: 3px;

		}
	}

	//INVENTORY

	.inventory {
		padding: 4px 10px;

		&-dropdown {
			width: 35%;
		}
	}

	// REFERRAL

	.referral-title {
		padding: 15px 0 10px;

		h2 {
			font-size: 18px;

		}
	}
	.referral {
		font-size: 14px;

		a {
			text-decoration: none;
		}
	}
}

@media (max-width: 768px) {

	// HEADER

	.main_logo {
		width: 300px;
		margin-left: 0;
	}

	.headertext {

		font-size: 12px;
	}

	.social-wrapper {
		padding-bottom: 10px;

		&-item {
			width: 35px;
			height: 35px;

		}
	}
	//NAVBAR

	.menubar {

		&-links {
			font-weight: normal;
			font-size: 14px;
			padding: 4px 6px 4px 6px;
		}
	}

	// SLIDER

	.slider {

		.carousel-item {
			height: 340px;

			&-info {
				top: 20px;
				padding: 10px 15px;

				.title {
					font-weight: normal;
					font-size: 16px;
					margin-bottom: 10px;
					padding-right: 30px;
				}

				.miles {
					font-size: 14px;
					margin-bottom: 10px;
				}

				button {
					font-size: 12px;
					padding: 7px 14px;
				}
			}


		}

		.prev-button {

			width: 100px;
			height: 150px;

			img {
				width: 15px;
				height: 20px;
			}
		}

		.next-button {

			width: 100px;
			height: 150px;

			img {
				width: 15px;
				height: 20px;
			}
		}

		.indicators-group {
			top: 90%;

			button {
				margin: 0 2px 0 2px;
				width: 16px;
				height: 4px;

			}
		}
	}


	//ACCORDION

	.browse-title {
		height: 40px;
		padding: 5px 25px;
		font-size: 20px;
	}

	.queries {
		//padding: 10px 10px 20px;
	}
	.queries-par {
		font-size: 14px !important;
		padding: 10px 20px !important;

	}

	// FOOTER

	.footer {

		&-title {
			font-size: 16px;
			padding: 10px 10px 0 20px;
			color: #b4b4b4;
			margin-bottom: 20px;

		}

		&-info {
			color: #b4b4b4;
			font-size: 14px;
			line-height: 22px;
			padding: 0 10px 0 20px;
		}

		.size {
			width: 60%;
			//margin: 0 auto;
		}

	}

	// BELOW FOOTER

	.below-footer {

		justify-content: space-between;
		flex-direction: column;
		align-items: center;
		padding: 10px 20px 10px;

		&-pas {
			margin-left: 5px;
			font-size: 12px;
		}

		&-links {
			margin-right: 20px;

			a {
				color: #d3d3d3;
				text-decoration: none;
				margin-left: 20px;
				font-size: 14px;
			}
		}
	}

	//CAR CARD
	.car-card {

		&-photo {
			width: 100%;
			display: block;
			margin: 0 auto 10px;
		}

		&-price {
			color: green;
			font-weight: normal;
		}

		&-made {
			text-transform: capitalize;
		}

		&-descr {
			background-color: $main-color;
			padding: 7px 5px 7px;
			margin-bottom: 10px;
			font-size: 12px;
		}

		&-button {
			padding: 6px 6px 6px;
			border-color: lightgrey;
			border-radius: 2px;
		}
	}
	//INVENTORY

	.inventory {
		padding: 4px 10px;

		&-dropdown {
			width: 100%;
		}
	}

	// SINGLE CAR SLIDER

	.single-car-slider {

		.prev-button {

			img {
				width: 25px;
				height: 30px;
			}
		}

		.next-button {

			img {
				width: 25px;
				height: 30px;
			}
		}

		.indicators-group {
			width: 1600px;
			height: 90px;
		}
	}

	.des {
		font-size: 14px;
	}

}

@media (max-width: 575px) {

	//HEADER
	.main_logo {
		width: 200px;
		display: block;
		margin: 0 auto;
	}

	.headertext {
		padding-top: 0px;
		margin-bottom: 10px;
		margin-top: 2px;
		text-align: center;
	}

	.social-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 0;

		&-item {
			width: 35px;
			height: 35px;
			margin: 0 5px 5px 5px;

		}
	}
	.menubar {
		width: 100%;
		justify-content: space-around;
		margin-left: 0;

		&-links {
			font-weight: normal;
			font-size: 9px;
		}
	}
	// SLIDER

	.slider {

		.carousel-item {
			height: 240px;

			&-info {
				top: 10px;
				padding: 5px 5px;

				.title {
					font-weight: normal;
					font-size: 14px;
					margin-bottom: 5px;
					padding-right: 10px;
				}

				.miles {
					font-size: 11px;
					margin-bottom: 5px;
				}

				.price {
					font-size: 16px;
				}

				button {
					font-size: 12px;
					padding: 7px 14px;
				}
			}


		}

		.prev-button {

			width: 50px;
			height: 100px;

			img {
				width: 15px;
				height: 20px;
			}
		}

		.next-button {

			width: 50px;
			height: 100px;

			img {
				width: 15px;
				height: 20px;
			}
		}

		.indicators-group {
			top: 90%;

			button {
				margin: 0 2px 0 2px;
				width: 10px;
				height: 2px;

			}
		}
	}
	.arrivals {
		margin-top: 10px;

		&-title {
			margin-right: 5px;
			font-size: 14px;
		}

		&-grey-block {
			height: 10px;
			padding-bottom: 3px;

		}
	}

	// FOOTER

	.footer {

		&-title {
			font-size: 16px;
			padding: 10px 7px 0 20px;
			margin-bottom: 20px;

		}

		&-info {
			font-size: 12px;
			line-height: 22px;
			padding: 0 10px 0 20px;
		}

		.size {
			width: 80%;
			//margin: 0 auto;
		}

	}

	// BELOW FOOTER

	.below-footer {
		justify-content: space-between;
		flex-direction: column;
		align-items: center;
		padding: 10px 20px 10px;

		&-pas {
			margin-left: 0;
			font-size: 12px;
		}

		&-links {
			margin-right: 0;

			a {
				display: block;
				color: #d3d3d3;
				text-decoration: none;
				margin: 0 auto;
				font-size: 12px;
				text-align: center;
			}
		}
	}
	// REFERRAL

	.referral-title {
		padding: 10px 0 5px;

		h2 {
			font-size: 16px;
		}
	}
	.referral {
		font-size: 14px;

		a {
			text-decoration: none;
		}
	}
	// SINGLE CAR SLIDER

	.single-car-slider {

		.prev-button {
			width: 40%;
			&:hover {
				background-color: rgba(0, 0, 0, 0);
			}

			img {
				display: none;
			}
		}

		.next-button {
			width: 40%;
			&:hover {
				background-color: rgba(0, 0, 0, 0);
			}

			img {
				display: none;
			}
		}

		.indicators-group {
			width: 1600px;
			height: 60px;
		}
	}

	.des {
		font-size: 12px;
	}

.contain{
	height: 240px;
}
}

