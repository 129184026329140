$main-color: #EBEBEB;
$red-color: #a81c1c;

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
}

body {
  color: #747474;
  font-size: 13px;
  //background-color: #282c34;
  //background: url(../images/bigstock-Wrecked-Vehicles-On-The-Junkya-305526229-768x373.jpg) center center/cover no-repeat fixed;
  background: url('assets/images/background-striped.png') fixed
}

h1 {
  opacity: 0
}

h2 {
  font-size: 24px;
  line-height: 33px;
}

h3 {
  font-size: 18px;
  line-height: 26px;
}

h4 {
  font-size: 15px;
}

h5 {
  font-size: 13px;
}

h6 {
  font-size: 11px;
}

//TOP NAVBAR
.shadow {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5); /* Параметры тени */

}

.navbar {
  width: 100%;
  border-bottom: 1px solid #E7E7E7;

  &-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;

    &-item {
      padding: 5px;

      a {
        color: black;
        text-decoration-line: none;
        cursor: pointer;
      }

      button {
        background: none;
        border: none;
      }
    }
  }
}

// HEADER

.main_logo {
  width: 400px;
  margin-left: 20px;
  display: inline-block;
  transition: 1s all;

  img {
    width: 100%;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.headertext {
  padding-top: 20px;
  margin-bottom: 10px;
  text-align: right;
  font-size: 20px;

  a {
    text-decoration: none;
    color: inherit;
  }
}

.social-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 10px;

  &-item {
    width: 45px;
    height: 45px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    transition: 0.5s all;

    &:hover {
      transform: scale(1.2);
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

//NAVBAR

.menubar {
  margin-left: 20px;
  width: 80%;
  display: flex;
  align-items: center;

  &-links {
    justify-content: center;
    color: #5f5f5f;
    text-decoration-line: none;
    font-size: 16px;
    font-weight: bold;
    padding: 9px 12px 6px 12px;
    height: 100%;
    transition: 0.5s all;
    text-transform: uppercase;

    &:hover {
      background-color: #a81c1c;
      color: #E7E7E7;
    }
  }
}

// SLIDER

.slider {

  .carousel-item {
    height: 600px;
    width: 100%;
    overflow: hidden;
    position: relative;

    &-img {

      width: 100%;
      height: 100%;
      //transform: translateY(-150px);
      object-fit: cover;
    }

    &-info {
      position: absolute;
      top: 40px;
      padding: 20px 20px;
      background-color: rgba(0, 0, 0, 0.7);
      text-transform: capitalize;
      color: #E7E7E7;

      .title {
        font-weight: normal;
        margin-bottom: 20px;
        padding-right: 30px;
        font-size: 30px;
      }

      .miles {
        margin-bottom: 20px;
        font-size: 22px;
      }

      .price {
        margin-bottom: 25px;
        font-weight: 700;
      }

      button {
        background-color: rgba(168, 28, 28, 0.7);
        border-style: none;
        color: #E7E7E7;
        padding: 9px 14px;
        font-size: 18px;
        line-height: normal;

        &:hover {
          background-color: rgba(168, 28, 28, 1);
        }
      }
    }
  }


  .prev-button {
    position: absolute;
    width: 100px;
    height: 200px;
    bottom: 0;
    left: 0;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    transition: 1s all;

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);

      img {
        opacity: 1;
      }
    }

    img {
      width: 35px;
      height: 40px;
      opacity: 0.5;
    }
  }

  .next-button {
    position: absolute;
    width: 100px;
    height: 200px;
    bottom: 0;
    right: 0;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    transition: 1s all;

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);

      img {
        opacity: 1;
      }
    }

    img {
      width: 35px;
      height: 40px;
      opacity: 0.5;
    }
  }

  .indicators-group {
    position: absolute;
    top: 94%;
    left: 50%;
    transform: translateX(-50%);

    button {
      margin: 0 2px 0 2px;
      width: 40px;
      height: 5px;
      opacity: 0.5;
      background-color: white;
      border: none;

      &.active-button {
        background-color: white;
        opacity: 1;
        transition: 1s all;
      }
    }
  }
}

// SINGLE CAR SLIDER

.single-car-slider {
  overflow: hidden;

  .carousel-item {
    max-height: 600px;
    width: 100%;
    overflow: hidden;
    position: relative;

    &-img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .prev-button {
    position: absolute;
    width: 100px;
    height: 100%;
    bottom: 0;
    left: 0;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    transition: 1s all;

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);

      img {
        opacity: 1;
      }
    }

    img {
      width: 35px;
      height: 40px;
      opacity: 0.5;

    }
  }

  .next-button {
    position: absolute;
    width: 100px;
    height: 100%;
    bottom: 0;
    right: 0;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    transition: 1s all;

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);

      img {
        opacity: 1;
      }
    }

    img {
      width: 35px;
      height: 40px;
      opacity: 0.5;
    }
  }

  .indicators-group {
    //width: 1600px;
    //height: 120px;
    padding-top: 5px;
  }

  button {
    height: 100%;
    border: none;
    padding: 0;
    margin: 0 2px 0 2px;

    img {
      opacity: 0.3;
      object-fit: contain;
      transition: 1s all;

      &.active-button {
        background-color: white;
        opacity: 1;
      }
    }
  }
}

.slide-right-1step {
  transform: translateX(-370px);
  transition: 1s all;
}

.slide-right-2step {
  transform: translateX(-510px);
  transition: 1s all;
}

// GREY LINE

.gray-line {
  height: 4px;
  background-color: lightgray;
  width: 100%;
}

//ACCORDION

.visibility {
  display: block;
}

.browse-title {
  background: #a81c1c;
  height: 50px;
  color: #E7E7E7;
  padding: 10px 25px;
  font-size: 20px;
}
.browse-click {
  font-size: 12px;
  font-style: italic;

}
.queries {
  background-color: #EBEBEB;
  padding: 10px 15px 10px;
  display: flex;
  flex-direction: column;
}

.dropdown-item {
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.arrivals {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &-title {
    color: #747474;
    display: block;
    margin-right: 10px;
  }

  &-grey-block {

    display: inline-block;
    height: 15px;
    width: 100%;
    background-color: #EBEBEB;
    padding-bottom: 3px;
  }
}


//CAR CARD

.car-card {
  padding: 15px;

  &-wrapper {
    position: relative;
    display: block;

    &-tag {
      position: absolute;
      top: 20px;
      left: 0;
      display: flex;
      justify-content: flex-start;

      &-text {
        padding: 5px 10px 5px 10px;
        font-size: 16px;
        color: white;
        background-color: rgba(0, 0, 0, 0.7);
      }

      &-color {
        width: 15px;
      }
    }

    &-photo {
      width: 100%;
      display: block;
      margin: 0 auto 10px;

    }

  }

  &-price {
    color: green;
    font-weight: normal;
  }

  &-made {
  //  text-transform: capitalize;
    font-size: 15px;
    min-height: 50px;
  }

  &-descr {
    background-color: $main-color;
    padding: 7px 5px 7px;
    margin-bottom: 10px;
  }

  &-button {
    padding: 6px 6px 6px;
    border-color: lightgrey;
    border-radius: 2px;
  }
}

// FOOTER

.footer {
  padding: 15px 15px 10px;
  background: url("assets/images/subtle_carbon.jpg");

  &-title {
    text-transform: uppercase;
    padding: 10px 10px 0 20px;
    color: #b4b4b4;
    margin-bottom: 20px;
  }

  &-info {
    color: #b4b4b4;
    line-height: 25px;
    padding: 0 10px 0 20px;

    &-item {
      display: flex;
      justify-content: space-between;
    }
  }
}

// BELOW FOOTER

.below-footer {
  background-color: #282A2B;
  border-top: 2px solid #747474;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px 10px;

  &-pas {
    color: #747474;
    margin-left: 20px;
  }

  &-links {
    margin-right: 20px;

    a {
      color: #d3d3d3;
      text-decoration: none;
      margin-left: 20px;
      font-size: 14px;
    }
  }
}

//INVENTORY

.inventory {
  padding: 10px 10px;
  display: flex;
  flex-direction: row-reverse;

  &-dropdown {
    width: 30%;
  }
}

// REFERRAL

.referral-title {
  background: url("assets/images/subtle_carbon.jpg");
  width: 100%;
  padding: 30px 0 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    color: #E7E7E7;
  }
}

.referral {
  font-size: 18px;

  a {
    text-decoration: none;
  }
}

//CONTACT US

.contact {
  margin-bottom: 20px;

  &-title {
    color: #747474;
  }

  &-grey-block {
    display: inline-block;
    height: 15px;
    width: 65%;
    background-color: #EBEBEB;
    padding-bottom: 3px;
  }
}

.contact-button {
  margin-top: 15px;
  border-color: lightgrey;
  padding: 7px 20px;
}

//SINGLE CAR

.single-car {
  &-img {
    display: block;
    margin: 0 auto;
  }
}

.des {
  white-space: pre-wrap;
  padding: 10px;
}

// UPLOADED FILE

.uploaded-file {
  display: flex;
  justify-content: flex-start;

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40%;

    img {
      height: 80px;
    }
  }

  button {
    margin-left: 20px;
    border: none;
    background: none;
    transition: 0.5s all;

    &:hover {
      transform: scale(1.2);
    }

    img {
      height: 80px;
    }
  }
}

//SINGLE CAR ITEM

.list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0 10px 30px;

  img {
    width: 200px;
  }

  &-description {
    width: 40%;
    margin-left: 20px;
  }
}

//EDIT SELECTED CAR

.uploaded-item {
  padding: 10px;

  img {
    width: 100%;
  }

  //button {
  //  display: block;
  //  margin: 0 auto;
  //  transition: 0.5s all;
  //
  //  &:hover {
  //    background-color: #5f5f5f;
  //    color: white;
  //    transform: scale(1.1);
  //  }
  //}
}

// SIGN IN

.sign-in {
  padding: 50px 0 150px;

  h2 {
    text-align: center;
    margin-bottom: 60px;
  }
}

.greeting {
  min-height: 46vh;
  padding-top: 100px;
  text-align: center;
}

.minVh46 {
  min-height: 46vh
}

.error-description {
  text-align: center;
  color: red
}


.admin-uploader {
  width: 100px;
  margin: 0 auto;

  img {
    width: 100%;
  }
}


.asds {
  width: 100%;
  height: 220px;
  object-fit: contain;
}

.contain {
  width: 100%;
  height: 280px;
  object-fit: contain;
}


@import "./scss/base/media";
@import "~react-image-gallery/styles/scss/image-gallery.scss";
//@import '~bootstrap/scss/bootstrap.scss';
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 66px;
  width: 60px;
}
